import React from "react";
import { TablePagination } from "../components/table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useLoaderData, useRevalidator, useSearchParams } from "react-router";
import { useTranslation } from "react-i18next";
import ButtonLoader from "../components/ButtonLoader";
import * as qs from "../qs";
import * as p from "../parser";
import { formData, FormErrorList, useFormAction } from "../form";
import { debounce } from "shared/src/util.mjs";
import { DEBOUNCE_INPUT } from "../constants";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import { loadServicesWithTemplates, patchService } from "../services/api";
import { ServiceTemplateDialog } from "../components/services/ServiceTemplateDialog.jsx";
import { CreateServiceDialog } from "../components/services/CreateServiceDialog";
import { DeleteServiceDialog } from "../components/services/DeleteServiceDIalog";

const defaults = [
  [["page", "limit"], 10],
  [["page", "offset"], 0],
];

const parsers = [
  [["page", "limit"], (val) => p.parseInt(val, 10)],
  [["page", "offset"], (val) => p.parseInt(val, 0)],
];

const filters = [[["filter", "name", "contains"], (v) => v.length === 0]];

function graphqlArgs(request) {
  const args = qs.parse(new URL(request.url).searchParams.toString(), {
    defaults: defaults,
    parsers: parsers,
    filters: filters,
  });

  args.filter = {
    ...args.filter,
    visible: { eq: true },
  };

  return args;
}

export async function loader({ request }) {
  return { services: await loadServicesWithTemplates(graphqlArgs(request)) };
}

function ServiceDialog({ setEditService, editService, calendars }) {
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const { loading, errors, onSubmit } = useFormAction(
    (e) => patchService(formData(e.currentTarget)),
    () => {
      revalidator.revalidate();
      setEditService(null);
    },
  );

  return (
    <Dialog open={true} onClose={() => setEditService(null)}>
      <DialogTitle>{t("service.editTitle")}</DialogTitle>
      <form onSubmit={onSubmit}>
        <input type="hidden" name="id" value={editService.id} />
        <DialogContent>
          <Box display="flex" flexDirection="column" gap="1rem">
            <TextField
              name="name"
              label={t("services.name")}
              defaultValue={editService.name}
              required
            />
            <TextField
              name="duration"
              label={t("services.duration")}
              defaultValue={editService.duration}
              type="number"
              required
            />
            <TextField
              name="color"
              label={t("services.color")}
              defaultValue={editService.color}
              type="color"
              required
            />
            <FormControl>
              <InputLabel>{t("services.calendars")}</InputLabel>
              <Select
                label={t("services.calendars")}
                name="calendar_ids"
                defaultValue={editService.calendars.map((c) => c.id)}
                multiple
              >
                {calendars.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormErrorList formErrors={errors} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditService(null)}>
            {t("form.cancel")}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <ButtonLoader />}
            {t("form.submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function useChangeQuery() {
  const [_, setSearchParams] = useSearchParams();
  return React.useCallback(
    (newValues) => {
      setSearchParams((prev) => ({
        ...Object.fromEntries(prev),
        ...newValues,
      }));
    },
    [setSearchParams],
  );
}

function useSearch() {
  const changeQuery = useChangeQuery();

  return React.useCallback(
    debounce(
      (term) =>
        changeQuery({
          "page[offset]": 0,
          "filter[name][contains]": term,
        }),
      DEBOUNCE_INPUT,
    ),
    [changeQuery],
  );
}

export default function Services() {
  const { t } = useTranslation();
  const loaderData = useLoaderData();

  const servicesConnection = loaderData?.services?.data?.services;
  const calendars = loaderData?.services?.data?.calendars?.data || [];

  const [editService, setEditService] = React.useState(null);
  const [editServiceTemplate, setEditServiceTemplate] = React.useState(null);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [deleteService, setDeleteService] = React.useState(null);
  const search = useSearch();
  const [searchParams] = useSearchParams();

  return (
    <>
      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <TextField
          type="search"
          defaultValue={searchParams.get("filter[name][contains]") ?? ""}
          onInput={(e) => search(e.target.value)}
          slotProps={{
            input: {
              endAdornment: <SearchIcon />,
            },
          }}
        />
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setCreateDialogOpen(true)}
        >
          {t("service.create")}
        </Button>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("services.color")}</TableCell>
            <TableCell>{t("services.name")}</TableCell>
            <TableCell>{t("services.duration")}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {servicesConnection.data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Box
                  sx={{
                    backgroundColor: row.color,
                    width: "1rem",
                    height: "1rem",
                  }}
                ></Box>
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>
                {t("services.duration_format", { minutes: row.duration })}
              </TableCell>
              <TableCell sx={{ display: "flex", direction: "row" }}>
                <IconButton
                  onClick={() => setEditService(row)}
                  sx={{ display: "flex" }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => setEditServiceTemplate(row)}
                  sx={{ display: "flex" }}
                >
                  <IntegrationInstructionsIcon />
                </IconButton>
                <IconButton
                  onClick={() => setDeleteService(row)}
                  sx={{ display: "flex" }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination connection={servicesConnection} />
          </TableRow>
        </TableFooter>
      </Table>
      {editService && (
        <ServiceDialog
          editService={editService}
          setEditService={setEditService}
          calendars={calendars}
        />
      )}
      {editServiceTemplate && (
        <ServiceTemplateDialog
          editServiceTemplate={editServiceTemplate}
          setEditServiceTemplate={setEditServiceTemplate}
        />
      )}
      {createDialogOpen && (
        <CreateServiceDialog
          onClose={() => setCreateDialogOpen(false)}
          calendars={calendars}
        />
      )}
      {deleteService && (
        <DeleteServiceDialog
          service={deleteService}
          onClose={() => setDeleteService(null)}
        />
      )}
    </>
  );
}
