import { config } from "../config/config";
import * as auth from "../auth";
import { saveIntendedUrl } from "../utils/util";
import { workingDays } from "shared/src/calendar.mjs";

/**
 * @param {string} path
 * @param {RequestInit} [opts]
 */
async function apiFetch(path, opts) {
  const authToken = auth.getAuthenticationToken();

  const resp = await fetch(`${config.apiUrl}${path}`, {
    ...opts,
    headers: {
      ...opts?.headers,
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
    },
  });

  if (resp.ok) {
    return resp.json();
  } else if (resp.status === 401) {
    saveIntendedUrl(window.location.pathname + window.location.search);
    auth.reAuthenticate();
    throw new Response("Unauthorized", { status: 401 });
  } else {
    throw new Error(`Failed to load resource at path ${path}.`);
  }
}

function workingHours(selection) {
  return workingDays.map((day) => day + " {" + selection + "}").join(" ");
}

export async function loadAppointments(payload) {
  return loadGraphql({
    query:
      "query appointments($filter: AppointmentConnectionFilterInput!, $order: AppointmentConnectionOrderInput) { appointments(filter: $filter, order: $order) {data {id calendar_id start end status_id description client {id first_name last_name birth_number phone} service {id color name} audit {data {created_at changed_fields}} }} }",
    variables: payload,
  });
}

export async function loadCalendarAppointments(payload) {
  return loadGraphql({
    query: `query appointments($filter: AppointmentConnectionFilterInput!) {
      appointments(filter: $filter) {
        data {
          id calendar_id start end status_id description
          client {id first_name last_name}
          service {id color name}
          audit {data {created_at changed_fields}}
        }
      }
    }`,
    variables: payload,
  });
}

export async function loadFlooreManagerAppointments(payload) {
  return loadGraphql({
    query: `query appointments($filter: AppointmentConnectionFilterInput! $order: AppointmentConnectionOrderInput $auditFilter: AuditConnectionFilterInput) {
        appointments(filter: $filter, order: $order) {
          data {
            id calendar_id location_id actual_calendar_id start end status_id description
            client {id first_name last_name}
            service {id color name duration}
            audit(filter: $auditFilter) {data {created_at changed_fields row_data action}}
            employee {id first_name last_name}
          }
        } 
      }`,
    variables: payload,
  });
}

/**
 * @param {import("shared/src/gql-types.mjs").PatchAppointmentInput} payload
 */
export function patchAppointment(payload) {
  return loadGraphql({
    query:
      "mutation patchAppointment($input: PatchAppointmentInput!) {patchAppointment(input: $input) {id} }",
    variables: { input: payload },
  });
}

/**
 * @param {import('shared/src/gql-types.mjs').CreateAppointmentInput} payload
 */
export function createAppointment(payload) {
  return loadGraphql({
    query:
      "mutation createAppointment($input: CreateAppointmentInput!) {createAppointment(input: $input) {id} }",
    variables: { input: payload },
  });
}

/**
 * @param {import('shared/src/gql-types.mjs').CreateCalendarInput} payload
 */
export function createCalendar(payload) {
  return loadGraphql({
    query:
      "mutation createCalendar($input: CreateCalendarInput!) {createCalendar(input: $input) {id}}",
    variables: { input: payload },
  });
}

export function deleteAppointment(payload) {
  return loadGraphql({
    query:
      "mutation deleteAppointment($input: DeleteAppointmentInput!) {deleteAppointment(input: $input) {id} }",
    variables: { input: payload },
  });
}

export async function loadCalendarData(variables) {
  return loadGraphql({
    query: `query data($calendarFilter: CalendarConnectionFilterInput) {
      appointmentStatuses {data {id name}}
      locations {data {id name working_hours {${workingHours("valid_from valid_to from to")}}}}
      calendars(filter: $calendarFilter) {data {id title: name location_id}}
    }`,
    variables: variables,
  });
}

export function loadClients(variables) {
  return loadGraphql({
    query:
      "query clients($page: PageInput!, $filter: ClientConnectionFilterInput) { clients(page: $page, filter: $filter) {page {limit offset} count data {id first_name last_name}}}",
    variables: variables,
  });
}

export function loadServices(variables) {
  return loadGraphql({
    query: `query services($page: PageInput!, $filter: ServiceConnectionFilterInput) {
      services(page: $page, filter: $filter) { 
        page {
          limit 
          offset
        } 
        count 
        data {
          id 
          name 
          color 
          duration
          visible
          calendars {
            id
          }
        }
      }
      calendars {
        data {
          id 
          name
        }
      }
    }`,
    variables,
  });
}

export function loadServicesWithTemplates(variables) {
  return loadGraphql({
    query: `query services($page: PageInput!, $filter: ServiceConnectionFilterInput) {
      services(page: $page, filter: $filter) { 
        page {
          limit 
          offset
        } 
        count 
        data {
          id 
          name 
          color 
          duration
          visible
          calendars {
            id
          }
          templates {
            id 
            version 
            template 
            service_id
          }
        }
      }
      calendars {
        data {
          id 
          name
        }
      }
    }`,
    variables,
  });
}

export function loadCalendars(variables) {
  return loadGraphql({
    query: `query calendars($page: PageInput!, $filter: CalendarConnectionFilterInput) {
         calendars(page: $page, filter: $filter) {page {limit offset} count data {id name visible location {name}}}
      }`,
    variables,
  });
}

export function loadCalendarEditData(variables) {
  return loadGraphql({
    query:
      "query calendar($id: ID!) { calendar(id: $id) {id name visible location {name}} }",
    variables,
  });
}

export function loadLocationEditData(variables) {
  return loadGraphql({
    query: `query location($id: ID!) { location(id: $id) {id name working_hours {${workingHours("valid_from valid_to from to")}}} }`,
    variables,
  });
}

/**
 * @param {import('shared/src/gql-types.mjs').PatchLocationInput} payload
 */
export function patchLocation(payload) {
  return loadGraphql({
    query:
      "mutation patchLocation($input: PatchLocationInput!) {patchLocation(input: $input) {id} }",
    variables: { input: payload },
  });
}

export function loadLocations(variables) {
  return loadGraphql({
    query:
      "query locations($page: PageInput!) { locations(page: $page) { page {limit offset} count data {id name}}}",
    variables,
  });
}

/**
 * @param {{query: string, operationName: string, variables: object}} params
 * @param {RequestInit} [opts]
 */
async function loadGraphql(params, opts) {
  return apiFetch(`/api`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
    ...opts,
  });
}

export function loadClientData(variables) {
  return loadGraphql({
    query: `
      query client($id: ID!) {
        client(id: $id) {
          id
          first_name
          last_name
          email
          birth_number
          phone
          street
          city
          post_code
        }
      }
    `,
    variables,
  });
}

/**
 * @param {import('shared/src/gql-types.mjs').PatchServiceInput} payload
 */
export function patchService(payload) {
  return loadGraphql({
    query:
      "mutation patchService($input: PatchServiceInput!) {patchService(input: $input) {id} }",
    variables: { input: payload },
  });
}

/**
 * @param {import('shared/src/gql-types.mjs').PatchCalendarInput} payload
 */
export function patchCalendar(payload) {
  return loadGraphql({
    query:
      "mutation patchCalendar($input: PatchCalendarInput!) {patchCalendar(input: $input) {id} }",
    variables: { input: payload },
  });
}

/**
 * @param {{filter: import('shared/src/gql-types.mjs').ClientConnectionFilterInput}} variables
 * @param {{signal?: AbortSignal}} [opts]
 */
export function clientSearch(variables, opts) {
  return loadGraphql(
    {
      query: `query clients($page: PageInput!, $filter: ClientConnectionFilterInput) { 
      clients(page: $page, filter: $filter) {
        page {limit offset}
        count
        data {id first_name last_name birth_number phone street city post_code location_id}
      }
    }`,
      variables: variables,
    },
    opts,
  );
}

/**
 * @param {{filter: import('shared/src/gql-types.mjs').ServiceConnectionFilterInput}} variables
 * @param {{signal?: AbortSignal}} [opts]
 */
export function serviceOptions(variables, opts) {
  return loadGraphql(
    {
      query: `query services($filter: ServiceConnectionFilterInput) {
         services(filter: $filter) {data {id name duration}}
      }`,
      variables,
    },
    opts,
  );
}

/**
 * @param {{signal?: AbortSignal}} [opts]
 */
export function locationOptions(opts) {
  return loadGraphql(
    {
      query: `query locations {
         locations {data {id name}}
      }`,
    },
    opts,
  );
}

/**
 * @param {{filter: import('shared/src/gql-types.mjs').AppointmentConnectionFilterInput}} variables
 * @param {{signal?: AbortSignal}} [opts]
 */
export function futureAppointments(variables, opts) {
  return loadGraphql(
    {
      query: `query($filter: AppointmentConnectionFilterInput!) {
      appointments(filter: $filter, order: {asc: start}) {
        data {
          id
          start
          service {
            name
          }
        }
      }
    }`,
      variables,
    },
    opts,
  );
}

/**
 * @param {object} variables
 * @param {{signal?: AbortSignal}} [opts]
 */
export function appointmentSuggestions(variables, opts) {
  return loadGraphql(
    {
      query: `query appointmentSuggestions($filter: AppointmentSuggestionsFilterInput!) {
         appointmentSuggestions(filter: $filter) {data {start end calendar_id calendar {name}}}
      }`,
      variables,
    },
    opts,
  );
}

export async function loadLegalGuardians(variables) {
  return loadGraphql({
    query: `
      query legalGuardians($client_id: ID!) {
        legalGuardians(client_id: $client_id) {
          id
          first_name
          last_name
          birth_number
          phone
          email
        }
      }
    `,
    variables,
  });
}

export function loadTreatmentTypes(variables) {
  return loadGraphql({
    query: `
      query treatmentTypes($filter: TreatmentTypeFilterInput) {
        treatmentTypes(filter: $filter) {
          id 
          treatment
          note
        }
      }
    `,
    variables: variables,
  });
}

export function loadRiskAllergies(variables) {
  return loadGraphql({
    query: `query riskAllergies($client_id: ID!) {
         riskAllergies(client_id: $client_id) { 
           id
           name
           type
           note
         }
      }`,
    variables,
  });
}

export function loadClientActivityLogs(variables) {
  return loadGraphql({
    query: `
      query clientActivityLogs($client_id: ID!) {
        clientActivityLogs(client_id: $client_id) {
          id
          activity_type
          activity_reference
          description
          content
          attachment
          created_at
        }
      }
    `,
    variables: variables,
  });
}

export function loadClientActivityLog(clientId) {
  return loadGraphql({
    query: `
      query clientActivityLog($id: ID!) {
        clientActivityLog(clientId: $id) {
          id
          activity_type
          activity_reference
          description
          content
          created_at
        }
      }
    `,
    variables: {
      id: clientId,
    },
  });
}

export function createClientActivityLog(payload) {
  return loadGraphql({
    query: `
      mutation createClientActivityLog($input: CreateClientActivityLogInput!) {
        createClientActivityLog(input: $input) {
          id
          client_id
          activity_type
          description
          content
        }
      }
    `,
    variables: { input: payload },
  });
}

/**
 * @param {{signal?: AbortSignal}} [opts]
 */
export function availableNurses(variables, opts) {
  return loadGraphql(
    {
      query: `query availableNurses($filter: EmployeeConnectionFilterInput) {
        employees(filter: $filter) {
          data {id first_name last_name}}
      }`,
      variables,
    },
    opts,
  );
}

export function loadFlooreManagerEmployees(variables) {
  return loadGraphql({
    query: `query data($location_id: ID!, $employeeFilter: EmployeeConnectionFilterInput, $appointmentFilter: AppointmentConnectionFilterInput!) {
      location(id: $location_id) {
        id
        working_hours {${workingHours("valid_from valid_to from to")}}
      }
      employees(filter: $employeeFilter) {
        data {
          id
          first_name
          last_name
          available
        }
      }
      appointments(filter: $appointmentFilter) {
        data {
          id
          employee_id
          audit {data {created_at changed_fields row_data action}}
        }
      }
    }`,
    variables: variables,
  });
}

/**
 * Fetch the next appointment for a client
 * @param {string} clientId - The ID of the client
 */
export async function getNextAppointmentForClient(clientId) {
  const now = new Date().toISOString();

  return loadGraphql({
    query: `
      query getNextAppointmentForClient($client_id: String!, $now: String!) {
        appointments(
          filter: {
            client_id: { eq: $client_id }
            start: { gte: $now }
          }
          order: { asc: start }
        ) {
          data {
            id
            start
            service {
              name
            }
            client {
              first_name
              last_name
            }
          }
        }
      }
    `,
    variables: {
      client_id: clientId,
      now,
    },
  });
}

/**
 * Fetch a list of service templates
 * @param {object} filter - Filter for the templates
 * @param {object} page - Pagination info { limit, offset }
 */
export async function loadServiceTemplates({ filter, page }) {
  return loadGraphql({
    query: `
      query AppointmentTemplates($filter: AppointmentTemplateFilterInput, $page: PageInput!) {
        appointmentTemplates(filter: $filter, page: $page) {
          data {
            id
            title
            version
            template
            service_id
          }
          page {
            limit
            offset
          }
          count
        }
      }
    `,
    variables: {
      filter,
      page,
    },
  });
}

/**
 * Fetch a single service template by ID
 * @param {string} id - ID of the service template to fetch
 */
export async function loadServiceTemplateById(id) {
  return loadGraphql({
    query: `
      query ServiceTemplateById($id: ID!) {
        serviceTemplateById(id: $id) {
          id
          version
          template
          service_id
        }
      }
    `,
    variables: {
      id,
    },
  });
}

export function syncEmployeeShifts() {
  return loadGraphql({
    query: `mutation { syncEmployeeShifts }`,
  });
}

export function syncAvailableEmployees() {
  return loadGraphql({
    query: "mutation { syncAvailableEmployees }",
  });
}

/**
 * Create a new service template
 * @param {object} input - The service template input
 */
export async function createServiceTemplate(input) {
  return loadGraphql({
    query: `
      mutation CreateServiceTemplate($input: CreateServiceTemplateInput!) {
        createServiceTemplate(input: $input) {
          id
          version
          template
          service_id
        }
      }
    `,
    variables: {
      input,
    },
  });
}

/**
 * Update a service template
 * @param {string} id - ID of the template to update
 * @param {object} input - The update input
 */
export function patchServiceTemplate(input) {
  return loadGraphql({
    query: `
      mutation patchServiceTemplate($input: PatchServiceTemplateInput!) {
        patchServiceTemplate(input: $input) {
          id
          version
          template
          service_id
        }
      }
    `,
    variables: { input },
  });
}

/**
 * Fetch a list of document templates (for dropdown)
 * @param {object} filter - Filter for the templates
 * @param {object} page - Pagination info { limit, offset }
 */

/**
 * @param {{page: PageInput, filter?: NotificationFilterInput}} variables
 */
export function loadNotifications({ page, filter }) {
  return loadGraphql({
    query: `
      query GetNotifications($page: PageInput!, $filter: NotificationFilterInput) {
        notifications(page: $page, filter: $filter) {
          data {
            id
            category
            type
            title
            description
            date
            linkText
            linkHref
            isRead
            client_id
            employee_id
          }
          page {
            limit
            offset
          }
          count
        }
      }
    `,
    variables: {
      page: {
        limit: parseInt(page.limit) || 10,
        offset: parseInt(page.offset) || 0,
      },
      filter: filter || null,
    },
  });
}

export function loadUnreadNotificationsCount({ clientId, employeeId }, opts) {
  return loadGraphql(
    {
      query: `
      query GetUnreadCount($filter: NotificationFilterInput!) {
        notifications(
          page: { limit: 1, offset: 0 }
          filter: $filter
        ) {
          count
        }
      }
    `,
      variables: {
        filter: {
          isRead: false,
          client_id: clientId,
          employee_id: employeeId,
        },
      },
    },
    opts,
  );
}

export function loadLatestNotifications(
  { limit = 5, clientId, employeeId },
  opts,
) {
  return loadGraphql(
    {
      query: `
      query GetLatestNotifications($page: PageInput!, $filter: NotificationFilterInput) {
        notifications(
          page: $page,
          filter: $filter
        ) {
          data {
            id
            category
            type
            title
            description
            date
            linkText
            linkHref
            isRead
            client_id
            employee_id
          }
        }
      }
    `,
      variables: {
        page: { limit: parseInt(limit), offset: 0 },
        filter: {
          client_id: clientId,
          employee_id: employeeId,
        },
      },
    },
    opts,
  );
}

export function loadNotification(id, opts) {
  return loadGraphql(
    {
      query: `
      query GetNotification($id: ID!) {
        notification(id: $id) {
          id
          category
          type
          title
          description
          date
          linkText
          linkHref
          isRead
          client_id
          employee_id
          client {
            id
            first_name
            last_name
          }
          employee {
            id
            first_name
            last_name
            username
          }
        }
      }
    `,
      variables: { id },
    },
    opts,
  );
}

export function createNotification(input, opts) {
  return loadGraphql(
    {
      query: `
      mutation CreateNotification($input: CreateNotificationInput!) {
        createNotification(input: $input) {
          id
          category
          type
          title
          description
          date
          linkText
          linkHref
          isRead
          client_id
          employee_id
        }
      }
    `,
      variables: { input },
    },
    opts,
  );
}

export function markNotificationsRead(input, opts) {
  return loadGraphql(
    {
      query: `
      mutation MarkNotificationsRead($input: MarkNotificationsReadInput!) {
        markNotificationsRead(input: $input)
      }
    `,
      variables: { input },
    },
    opts,
  );
}

export function markAllNotificationsReadStatus({ isRead }, opts) {
  return loadGraphql(
    {
      query: `
      mutation MarkAllRead($input: MarkNotificationsReadInput!) {
        markNotificationsRead(input: $input)
      }
    `,
      variables: {
        input: {
          isRead,
          ids: [],
        },
      },
    },
    opts,
  );
}

/**
 * Fetch a list of products
 * @param {object} filter - Filter for the products
 * @param {object} page - Pagination info { limit, offset }
 */
export async function loadProducts({ page, filter }) {
  return loadGraphql({
    query: `
      query Products($page: PageInput!, $filter: ProductFilterInput) {
        products(page: $page, filter: $filter) {
          data {
            id
            name
            description
            services {
              id
              name
              color
              duration
            }
          }
          page {
            limit
            offset
          }
          count
        }
      }
    `,
    variables: {
      page,
      filter,
    },
  });
}

/**
 * Fetch a single product by ID
 * @param {string} id - ID of the product to fetch
 */
export async function loadProduct(id) {
  return loadGraphql({
    query: `
      query Product($id: ID!) {
        product(id: $id) {
          id
          name
          description
          services {
            id
            name
            color
            duration
          }
        }
      }
    `,
    variables: {
      id,
    },
  });
}

/**
 * Create a new product
 * @param {object} input - The product input
 */
export async function createProduct(input) {
  return loadGraphql({
    query: `
      mutation CreateProduct($input: CreateProductInput!) {
        createProduct(input: $input) {
          id
          name
          description
        }
      }
    `,
    variables: {
      input,
    },
  });
}

/**
 * Update a product
 * @param {object} input - The update input
 */
export async function patchProduct(input) {
  return loadGraphql({
    query: `
      mutation PatchProduct($input: PatchProductInput!) {
        patchProduct(input: $input) {
          id
          name
          description
        }
      }
    `,
    variables: { input },
  });
}

/**
 * Delete a product
 * @param {string} id - ID of the product to delete
 */
export async function deleteProduct(id) {
  return loadGraphql({
    query: `
      mutation DeleteProduct($id: ID!) {
        deleteProduct(id: $id) {
          id
        }
      }
    `,
    variables: {
      id,
    },
  });
}

export function createService(input) {
  return loadGraphql({
    query: `
    mutation createService($input: CreateServiceInput!) {
      createService(input: $input) {
        id
        name
        color
        duration
        calendars {
          id
          name
        }
      }
    }
  `,
    variables: { input },
  });
}

export function softDeleteService(id) {
  return loadGraphql({
    query: `
      mutation softDeleteService($id: ID!) {
        softDeleteService(id: $id) {
          id
          name
          visible
        }
      }
    `,
    variables: { id },
  });
}
