import "@event-calendar/core/index.css";
import React, { useEffect, useState } from "react";
import { useLoaderData, useRevalidator } from "react-router";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Modal,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { status } from "shared/src/appointment.mjs";
import * as d from "shared/src/date.mjs";
import * as api from "../services/api";
import * as f from "../utils/formatter";
import * as ws from "../utils/ws";
import { currentLocationFromRequest } from "../components/LocationSelector";
import { NewReservationSuggestionDialog } from "../components/new-reservation-dialog";
import {
  faAngry,
  faCake,
  faCalendarPlus,
  faCommentDots,
  faExclamationCircle,
  faExclamationTriangle,
  faGlobe,
  faMagnifyingGlass,
  faClose,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  faAlarmClock,
  faHourglassClock,
  faSeatAirline,
} from "@fortawesome/pro-solid-svg-icons";
import ServiceTag from "../components/ServiceTag.jsx";

export async function loader({ request }) {
  const location_id = currentLocationFromRequest(request);

  const now = new Date();
  const from = d.toString(d.startOfDay(now));
  const to = d.toString(d.endOfDay(now));

  return api.loadAppointments({
    filter: {
      start: { gte: from, lt: to },
      location_id: { eq: location_id },
    },
    order: {
      asc: "start",
    },
  });
}

export default function ReceptionDashboard() {
  const [reservationDialogOptions, setReservationDialogOptions] =
    useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const loaderData = useLoaderData();
  const appointments = loaderData.data.appointments.data;
  const [renderedAppointments, setRenderedAppointments] =
    useState(appointments);
  const [dontUpdateRenderedAppointments, setDontUpdateRenderedAppointments] =
    useState(false);
  const revalidator = useRevalidator();

  useEffect(() => {
    if (!dontUpdateRenderedAppointments) {
      setRenderedAppointments(appointments);
    }
  }, [appointments, dontUpdateRenderedAppointments]);

  useEffect(() => {
    async function refresh(msg) {
      if (msg.type === "appointment_changed") {
        revalidator.revalidate();
      }
    }

    ws.subscribe(refresh);

    return () => {
      ws.unsubscribe(refresh);
    };
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  function SnackbarNotification() {
    return (
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        // autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            backgroundColor: "white",
            boxShadow: "0 0.2rem 0.3rem rgba(0,0,0,0.5)",
            borderRadius: "0",
          }}
        >
          <div style={{ padding: "0.5rem 0", backgroundColor: "white" }}>
            <div
              style={{
                backgroundColor: "green",
                width: "0.7rem",
                height: "100%",
                borderRadius: "0 1rem 1rem 0",
              }}
            />
          </div>
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
            icon={
              <div
                style={{
                  backgroundColor: "lightgreen",
                  padding: "0.75rem",
                  borderRadius: "100%",
                  aspectRatio: "1 / 1",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              >
                <FontAwesomeIcon icon={faExclamationCircle} color="green" />
              </div>
            }
            style={{
              backgroundColor: "inherit",
              display: "flex",
              alignItems: "flex-start",
              marginLeft: "1rem",
            }}
          >
            <AlertTitle color="green" style={{ fontWeight: "bold" }}>
              Info
            </AlertTitle>
            <span style={{ color: "black" }}>{snackbarMessage}</span>
          </Alert>
        </div>
      </Snackbar>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h1">Dashboard Recepce</Typography>
        <Button
          variant="outlined"
          onClick={() =>
            setReservationDialogOptions({
              close: () => setReservationDialogOptions(null),
              showAdhocInput: true,
            })
          }
        >
          Nová rezervace
        </Button>
      </Box>

      {reservationDialogOptions && (
        <NewReservationSuggestionDialog {...reservationDialogOptions} />
      )}

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 20%",
          gridTemplateRows: "2",
          gridTemplateAreas: `"booked treated" "checkedIn treated"`,
          width: "100%",
          gap: "36px",
        }}
      >
        <div style={{ gridArea: "booked" }}>
          <BookedClientList
            appointments={renderedAppointments}
            setDontUpdateRenderedAppointments={
              setDontUpdateRenderedAppointments
            }
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
          />
        </div>
        <div style={{ gridArea: "checkedIn" }}>
          <CheckedInClientList appointments={renderedAppointments} />
        </div>
        <div style={{ gridArea: "treated" }}>
          <TreatedClientList appointments={renderedAppointments} />
        </div>
      </div>

      <SnackbarNotification />
    </>
  );
}

function ClientName({ appointment }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5em",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <a
          href={"/clients/" + appointment.client.id}
          style={{
            color: "inherit",
            textDecoration: "none",
            fontWeight: "bold",
            fontSize: "14px",
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {f.fullName(appointment.client)}
          </span>
        </a>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          color={theme.palette.error.indicator}
        />
        <FontAwesomeIcon
          icon={faCake}
          color={theme.palette.warning.indicator}
        />
        <FontAwesomeIcon
          icon={faGlobe}
          color={theme.palette.success.indicator}
        />
        <FontAwesomeIcon icon={faAngry} color={theme.palette.error.indicator} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          maxWidth: "100%",
        }}
      >
        <ServiceTag service={appointment.service} />
        <FontAwesomeIcon icon={faCommentDots} color="purple" />
      </div>
    </Box>
  );
}

function BookedClient({ appointment, setSnackbarMessage, setSnackbarOpen }) {
  const delayedBy = appointmentDelayedBy(appointment.start);
  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false);
  const handleOpenConfirmationModal = () => setOpenConfirmationModal(true);
  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);

  return (
    <>
      <Box
        sx={{
          borderBottomStyle: "solid",
          borderBottomColor: "divider",
          borderWidth: 1,
          padding: 1.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ flex: "0 0 60%", minWidth: "0", paddingRight: "40px " }}>
          <ClientName appointment={appointment} />
        </Box>
        <Box sx={{ flex: "0 0 30%", fontWeight: "bold", color: "grey" }}>
          {f.time24(appointment.start)}{" "}
          {!!delayedBy && (
            <Box
              component="span"
              sx={{ color: "_components.alert.error.color" }}
            >
              ({f.minutesToDuration(delayedBy)})
            </Box>
          )}
        </Box>
        <Box style={{ flex: "0 0 10%", textAlign: "right" }}>
          <IconButton
            sx={{
              borderRadius: 1,
              color: "white",
              backgroundColor: "secondary.main",
              width: "2.6rem",
              height: "2.6rem",
              fontSize: "1.8rem",
              "&:hover": {
                color: "secondary.main",
              },
            }}
            onClick={handleOpenConfirmationModal}
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Box>
      </Box>
      <ConfirmationModal
        appointment={appointment}
        handleCloseConfirmationModal={handleCloseConfirmationModal}
        openConfirmationModal={openConfirmationModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarOpen={setSnackbarOpen}
      />
    </>
  );
}

function CheckedInClient({ appointment }) {
  const [openAppointmentDetailModal, setOpenAppointmentDetailModal] =
    React.useState(false);
  const handleOpenAppointmentDetailModal = () =>
    setOpenAppointmentDetailModal(true);
  const handleCloseAppointmentDetailModal = () =>
    setOpenAppointmentDetailModal(false);

  let checkedInList = appointment.audit.data.filter(
    (appointment) =>
      appointment.changed_fields?.status_id === String(status.CONFIRMED),
  );

  if (checkedInList.length === 0) {
    checkedInList = appointment.audit.data.filter(
      (appointment) =>
        appointment.changed_fields?.status_id === String(status.WAITING),
    );
  }

  const checkedInMinutes = getCheckedInMinutes(
    checkedInList[checkedInList.length - 1],
    appointment,
  );

  const waitingMinutes = getWaitingTime(
    checkedInList[checkedInList.length - 1],
    appointment,
  );

  return (
    <Box
      sx={{
        display: "flex",
        borderBottomStyle: "solid",
        borderBottomColor: "divider",
        borderWidth: 1,
        alignItems: "center",
        padding: "12px",
      }}
    >
      <Box sx={{ flex: "0 0 60%" }}>
        <ClientName appointment={appointment} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "0 0 40%",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={handleOpenAppointmentDetailModal}
      >
        <Box sx={{ flex: "0 0 30%", color: "grey" }}>
          {f.time24(appointment.start)}
        </Box>
        <Box sx={{ flex: "0 0 fit-content", textAlign: "left" }}>
          <Box
            sx={{
              color:
                checkedInMinutes > 0 ? "_components.alert.error.color" : "blue",
            }}
          >
            <FontAwesomeIcon
              icon={faHourglassClock}
              style={{ marginRight: "5px" }}
            />
            {f.minutesToDuration(checkedInMinutes)}
          </Box>
          <Box
            sx={{
              color:
                waitingMinutes > 0 ? "_components.alert.error.color" : "blue",
            }}
          >
            <FontAwesomeIcon
              icon={faAlarmClock}
              style={{ marginRight: "5px" }}
            />
            {f.minutesToDuration(waitingMinutes)}
          </Box>
        </Box>
      </Box>
      <AppointmentDetailModal
        appointment={appointment}
        handleCloseAppointmentDetailModal={handleCloseAppointmentDetailModal}
        openAppointmentDetailModal={openAppointmentDetailModal}
        checkedInMinutes={checkedInMinutes}
        waitingMinutes={waitingMinutes}
      />
    </Box>
  );
}

function getCheckedInMinutes(checkedIn, appointment) {
  if (checkedIn) {
    const checkedInTime = d.parseUtcDateTime(checkedIn.created_at);
    const appointmentTime = d.parseDateTime(appointment.start);
    const diff = checkedInTime - appointmentTime;

    return Math.floor(diff / 1000 / 60);
  }
}

function getWaitingTime(checkedIn, appointment) {
  if (checkedIn) {
    const checkedInTime = d.parseUtcDateTime(checkedIn.created_at);
    const appointmentTime = d.parseDateTime(appointment.start);
    const diff = checkedInTime - appointmentTime;

    const now = new Date();
    let time;
    if (diff < 0) {
      time = now - appointmentTime;
    } else {
      time = now - checkedInTime;
    }
    return Math.floor(time / 1000 / 60);
  }
}

function TreatedClient({ appointment }) {
  return (
    <div
      style={{
        borderBottom: "2px solid lightblue",
        padding: "12px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flex: "0 0 60%",
        }}
      >
        <a
          href={"/clients/" + appointment.client.id}
          style={{
            color: "inherit",
            textDecoration: "none",
            fontWeight: "bold",
            fontSize: "14px",
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {f.fullName(appointment.client)}
          </span>
        </a>
        <FontAwesomeIcon icon={faGlobe} color="green" />
        <FontAwesomeIcon icon={faAngry} color="red" />
      </div>

      <Button variant="contained">
        <FontAwesomeIcon icon={faCalendarPlus} />{" "}
      </Button>
    </div>
  );
}

function BookedClientHeader() {
  return (
    <ColumnHeader>
      <span style={{ flex: "0 0 60%" }}>Jméno klienta</span>
      <span style={{ flex: "0 0 25%" }}>Start</span>
      <span style={{ flex: "0 0 15%" }}></span>
    </ColumnHeader>
  );
}

function BookedClientList({
  appointments,
  setDontUpdateRenderedAppointments,
  setSnackbarMessage,
  setSnackbarOpen,
}) {
  const [bookedClients, setBookedClients] = useState(appointments);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    if (searchTerm) {
      setBookedClients(
        searchAppointments(appointments, searchTerm, [
          status.BOOKED,
          status.NOSHOW,
        ]),
      );
    } else {
      setBookedClients(
        appointments.filter(
          (appointment) => appointment.status_id === status.BOOKED,
        ),
      );
    }
  }, [appointments, searchTerm]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h2">Plánovaní klienti</Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            placeholder="Hledat"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: "grid",
            gap: "0 1rem",
            gridAutoFlow: "row",
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
          onMouseEnter={() => setDontUpdateRenderedAppointments(true)}
          onMouseLeave={() => setDontUpdateRenderedAppointments(false)}
        >
          <BookedClientHeader />
          <BookedClientHeader />
          <BookedClientHeader />
          {bookedClients.slice(0, 15).map((appointment) => (
            <BookedClient
              appointment={appointment}
              key={appointment.id}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarOpen={setSnackbarOpen}
            />
          ))}
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "2rem",
            alignItems: "center",
            gap: "1rem",
          }}
        ></div>
      </CardContent>
    </Card>
  );
}

function ColumnHeader({ children }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 1.5,
        py: 1,
        textAlign: "left",
        backgroundColor: "background.default",
        fontSize: "1rem",
      }}
    >
      {children}
    </Box>
  );
}

function CheckedInClientHeader() {
  return (
    <ColumnHeader>
      <span style={{ flex: "0 0 60%" }}>Jméno klienta</span>
      <span
        style={{
          display: "flex",
          flex: "0 0 40%",
          justifyContent: "space-between",
        }}
      >
        <span style={{ flex: "0 0 30%" }}>Start</span>
        <span
          style={{ flex: "0 0 70%", whiteSpace: "nowrap", textAlign: "right" }}
        >
          Příchod/Čeká
        </span>
      </span>
    </ColumnHeader>
  );
}

function CheckedInClientList({ appointments }) {
  const [checkedInClients, setCheckedInClients] = useState(appointments);

  useEffect(() => {
    setCheckedInClients(
      appointments
        .filter(
          (appointment) =>
            appointment.status_id === status.CONFIRMED ||
            appointment.status_id === status.WAITING,
        )
        .sort((a, b) => a.status_id - b.status_id),
    );
  }, [appointments]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" gutterBottom>
            Čekající klienti
          </Typography>
        </Box>

        <div>
          <div
            style={{
              display: "grid",
              gap: "0 1rem",
              gridAutoFlow: "row",
              gridTemplateColumns: "1fr 1fr 1fr",
            }}
          >
            <CheckedInClientHeader />
            <CheckedInClientHeader />
            <CheckedInClientHeader />
          </div>
        </div>

        <div>
          <div
            style={{
              display: "grid",
              gap: "0 1rem",
              gridAutoFlow: "row",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridAutoRows: "1fr",
            }}
          >
            {checkedInClients.map((appointment) => (
              <CheckedInClient key={appointment.id} appointment={appointment} />
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function TreatedClientHeader() {
  return (
    <ColumnHeader>
      <span>Jméno klienta</span>
      <span>Objednat</span>
    </ColumnHeader>
  );
}

function TreatedClientList({ appointments }) {
  const [treatedClients, setTreatedClients] = useState(appointments);
  useEffect(() => {
    setTreatedClients(
      appointments
        .filter((appointment) => appointment.status_id === status.FINISHED)
        .sort(
          (a, b) =>
            new Date(
              a.audit.data.findLast(
                (change) =>
                  change?.changed_fields?.status_id === String(status.FINISHED),
              )?.created_at,
            ).getTime() -
            new Date(
              b.audit.data.findLast(
                (change) =>
                  change?.changed_fields?.status_id === String(status.FINISHED),
              )?.created_at,
            ).getTime(),
        ),
    );
  }, [appointments]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" gutterBottom>
            Po úkonu
          </Typography>
        </Box>

        <div
          style={{
            display: "grid",
            gridAutoFlow: "row",
            gridTemplateColumns: "1fr",
          }}
        >
          <TreatedClientHeader />

          {treatedClients.map((appointment) => (
            <TreatedClient key={appointment.id} appointment={appointment} />
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

function searchAppointments(appointments, searchTerm, statuses = []) {
  const searchTerms = searchTerm.split(" ").map((term) =>
    term
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, ""),
  );

  let filteredAppointments = appointments;
  if (statuses.length) {
    filteredAppointments = filteredAppointments.filter((appointment) =>
      statuses.includes(appointment.status_id),
    );
  }
  searchTerms.forEach((term) => {
    filteredAppointments = filteredAppointments.filter(
      (appointment) =>
        appointment.client.first_name
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .includes(term) ||
        appointment.client.last_name
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .includes(term) ||
        appointment.client.birth_number?.includes(term) ||
        appointment.client.phone?.includes(term) ||
        appointment.start.split(" ")[1].includes(term) ||
        appointment.start.split(" ")[1].replace(":", "").includes(term),
    );
  });
  return filteredAppointments;
}

function appointmentDelayedBy(appointmentTime) {
  const now = new Date();
  const appointment = d.parseDateTime(appointmentTime);
  const diff = now - appointment;
  if (diff < 0) {
    return 0;
  }
  const totalMinutes = Math.floor(diff / 1000 / 60);

  return totalMinutes;
}

function ConfirmationModal({
  appointment,
  handleCloseConfirmationModal,
  openConfirmationModal,
  setSnackbarMessage,
  setSnackbarOpen,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleMoveAppointment = () => {
    handleCloseConfirmationModal();
    api
      .patchAppointment({
        id: appointment.id,
        status_id: status.CONFIRMED,
      })
      .then(() => {
        setSnackbarMessage(
          <span>
            Klient <strong>{f.fullName(appointment.client)}</strong> byl
            přesunut do čekajících.
          </span>,
        );
        setSnackbarOpen(true);
      });
  };

  return (
    <Modal open={openConfirmationModal} onClose={handleCloseConfirmationModal}>
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Přesunutí do čekajících
          </Typography>
          <span
            style={{ cursor: "pointer" }}
            onClick={handleCloseConfirmationModal}
          >
            X
          </span>
        </div>
        <Typography id="modal-modal-description" sx={{ my: 2 }}>
          Opravdu chcete přesunout klienta
          <br />
          <strong>{f.fullName(appointment.client)}</strong> do čekajících?
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" onClick={handleCloseConfirmationModal}>
            Zrušit
          </Button>
          <Button variant="contained" onClick={handleMoveAppointment}>
            Přesunout
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

function AppointmentDetailModal({
  appointment,
  handleCloseAppointmentDetailModal,
  openAppointmentDetailModal,
  checkedInMinutes,
  waitingMinutes,
}) {
  const handleMoveAppointment = (status) => {
    handleCloseAppointmentDetailModal();
    api.patchAppointment({
      id: appointment.id,
      status_id: status,
    });
  };

  return (
    <Dialog
      open={openAppointmentDetailModal}
      onClose={handleCloseAppointmentDetailModal}
    >
      <DialogTitle>
        <Typography id="modal-modal-title" variant="h2">
          {f.fullName(appointment.client)}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseAppointmentDetailModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>

      <DialogContent>
        <ServiceTag service={appointment.service} />

        <div>
          <strong>Objednany termin:</strong> {f.time24(appointment.start)}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "min-content 1fr",
            gap: "10px",
          }}
        >
          <FontAwesomeIcon icon={faExclamationTriangle} color="red" />
          <div>
            <div style={{ fontWeight: "bold" }}>K doreseni</div>
            <span>Lorem ipsum dolor xxx</span>
          </div>
          <FontAwesomeIcon icon={faGlobe} color="green" />
          <div>
            <div style={{ fontWeight: "bold" }}>Jazyk klienta</div>
            <span>xxx</span>
          </div>
          <FontAwesomeIcon icon={faAngry} color="red" />
          <div>
            <div style={{ fontWeight: "bold" }}>Problemovy klient</div>
            <span>xxx</span>
          </div>
          <FontAwesomeIcon icon={faCommentDots} color="purple" />
          <div>
            <div style={{ fontWeight: "bold" }}>Dulezita poznamka</div>
            <span>xxx</span>
          </div>
        </div>

        <div style={{ color: "blue", fontWeight: "bold" }}>
          <FontAwesomeIcon icon={faSeatAirline} /> Kreslo se prave pripravuje
        </div>

        <div style={{ display: "flex", gap: "2rem" }}>
          <div>
            <FontAwesomeIcon
              icon={faAlarmClock}
              style={{ marginRight: "5px" }}
            />
            Čeká déle:{" "}
            <span style={{ color: waitingMinutes > 0 ? "red" : "blue" }}>
              {f.minutesToDuration(waitingMinutes)}
            </span>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faHourglassClock}
              style={{ marginRight: "5px" }}
            />
            Příchod:{" "}
            <span style={{ color: checkedInMinutes > 0 ? "red" : "blue" }}>
              {f.minutesToDuration(checkedInMinutes)}
            </span>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={() => handleMoveAppointment(status.WAITING)}
        >
          Vyreseno
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => handleMoveAppointment(status.BOOKED)}
        >
          Vratit do planovanych
        </Button>
      </DialogActions>
    </Dialog>
  );
}
